<template>
  <q-page class="q-pa-md q-gutter-y-md">
    <div class="row">
      <div class="col-12">
        <div class="text-h5 text-center">Access our Database of 20,000+ job listings</div>
      </div>
    </div>

    <div class="row text-center">
      <div class="col-12 col-sm-4 q-py-md">
        <div>
          <q-icon style="font-size: 4em;" color="secondary" name="fas fa-inbox"></q-icon>
        </div>
        <h5 class="q-my-sm"><strong>Newsletters</strong></h5>
        <p class="text-muted text-sm">
          We send out daily, weekly, and semi-weekly newsletters will thousands of job listings.
        </p>
      </div>

      <div class="col-12 col-sm-4 q-py-md">
        <div>
          <q-icon style="font-size: 4em;" color="secondary" name="far fa-database"></q-icon>
        </div>
        <h5 class="q-my-sm"><strong>Online Database</strong></h5>
        <p class="text-muted text-sm">Browse hundreds of thousands of jobs, updated daily.</p>
      </div>

      <div class="col-12 col-sm-4 q-py-md">
        <div>
          <q-icon style="font-size: 4em;" color="secondary" name="far fa-bell"></q-icon>
        </div>
        <h5 class="q-my-sm"><strong>Custom Job Alerts</strong></h5>
        <p class="text-muted text-sm">Get notified of specific jobs that match your needs.</p>
      </div>
    </div>

    <!-- <div class="row">
      <div class="col-12">
        <div class="text-h4 text-weight-bold text-center">Premium filters and alerts</div>
      </div>
    </div> -->

    <div class="q-mb-xl row">
      <div
        class="text-h4 full-width text-primary text-center text-weight-medium q-mb-lg"
      >Recently Posted Jobs
        <q-btn flat color="secondary" size="sm" icon-right="refresh" @click="reqData(true)">Refresh</q-btn>
      </div>

      <div class="col-12 q-px-md">
      <table class="table table-striped table-sm" style="width: 98%">
        <job-list-item v-for="post in recentJobs" :key="post._id" :post="post"></job-list-item>
      </table>
      </div>

      <div class="q-my-md col-12 text-center">
        <q-btn to="/jobs/list/1" color="blue-8" size="md" outline>
          More Jobs
          <q-icon class="q-ml-md" size=".9em" name="fas fa-chevron-right"></q-icon>
        </q-btn>
      </div>
    </div>

    <div v-if="siteArrays.site_counts" class="row">
      <div class="col-12 col-sm-6 q-pa-sm">
        <q-card flat bordered class="full-width">
          <q-card-section class="q-pa-sm stripecard-gray">
            <div class="text-center q-my-md">
              <q-icon style="font-size: 4em;" color="secondary" name="far fa-graduation-cap"></q-icon>
            </div>
            <div class="text-center text-h6 text-weight-bold striped">Top Degrees</div>

            <div class="row">
              <div v-for="deg in siteArrays.site_counts.degrees.slice(0, 21)" :key="deg.name" class="col-6 col-md-4 q-px-md">
                <div class="row" style="min-width: 100px;">
                  <div class="col-9">
                    <router-link to="/deg/name">{{ deg.name }}</router-link>
                  </div>
                  <div class="col-3 text-right">
                    {{ deg.count }}
                  </div>
                </div>
              </div>
            </div>
          </q-card-section>
        </q-card>
      </div>

      <div class="col-12 col-sm-6 q-pa-sm">
        <q-card flat bordered class="full-width">
          <q-card-section class="q-pa-sm stripecard-gray">
            <div class="text-center q-my-md">
              <q-icon style="font-size: 4em;" color="secondary" name="far fa-map-marker-alt"></q-icon>
            </div>
            <div class="text-center text-h6 text-weight-bold striped">Top States</div>

            <div class="row">
              <div v-for="loc in siteArrays.site_counts.states.slice(0, 20)" :key="loc.name" class="col-12 col-md-6 q-px-md">
                <div class="row" style="min-width: 100px;">
                  <div class="col-9">
                    <router-link to="/deg/name">{{ siteArrays.states[loc.name] }}</router-link>
                  </div>
                  <div class="col-3 text-right">
                    {{ loc.count }}
                  </div>
                </div>
              </div>
            </div>
          </q-card-section>
        </q-card>
      </div>
    </div>
  </q-page>
</template>

<script>
import { mapState } from 'vuex';

import jobModule from "../modules/job";

export default {
  name: 'Index',
  computed: {
    ...mapState('site', {
      siteArrays: 'arrs',
    }),
    ...mapState("job", {
      loading: "loading",
      totals: "totals",
      qry: "qry",

      // numJobs: 'totalRecords',
    }),
    recentJobs() {
      return this.$store.getters["job/getPageSet"](1).slice(0, 10);
    },
    topStates() {
      return this.$store.getters["job/stateTotalsCount"].slice(0, 10);
    },
    topSkills() {
      return this.$store.getters["job/skillTotalsCount"].slice(0, 10);
    },
    topOrgTypes() {
      return this.$store.getters["job/orgTypeTotalsCount"].slice(0, 10);
    },
  },
  created() {
    // this.reqData();
  },
  methods: {
    reqData(force) {
      if (force || !this.loaded) {
        this.$store.dispatch( 'job/' + jobModule.store.actionTypes.LoadRecordsPageRequest, { page_id: 1 } );
        this.$store.dispatch( 'job/' + jobModule.store.actionTypes.LoadTotalsRequest );
      }
    },
    doSearch() {
      console.log('doing search', this.kw);

      const nq = { ...this.qry, terms: this.kw.split(' ') };

      // this.$store.dispatch("post/" + PostActionTypes.PostSetFilterQry, this.qry).then( () => {
      this.$store.dispatch( 'job/' + jobModule.store.actionTypes.LoadRecordsPageRequest, { qry: nq, page_id: 1 } ).then( () => {
        console.log('going to URL');
        this.$router.push({name: 'jobSearch', params: { pageId: 1} });
      });

    },
  },
};
</script>
